import React, { useEffect } from 'react';
import { MdDeleteForever } from 'react-icons/md';
import { TokenType, GroupId } from '../../utils/enum';

function StreamsInputs({ formInputs, setFormInputs, newStream, setNewStream }) {
	const isAdmin = sessionStorage.getItem('isAdmin') === '1';
	const groupId = Number(sessionStorage.getItem('groupId'));
	const findStreamIndexToModify = (id) => {
		const indexOfStreamToModify = formInputs.streams.findIndex((a) => a.id === id);
		return indexOfStreamToModify;
	};
	useEffect(() => {
		if (formInputs && formInputs.streams) {
			const updatedStreams = formInputs.streams.map((stream) => {
				const { mime, ...rest } = stream;
				if (stream.type !== TokenType.M6 && stream.type !== TokenType.IndesRadios) {
					const { duration, ...updatedStream } = rest; // Supprime la clé duration
					return updatedStream;
				}
				return rest;
			});
			formInputs.streams = updatedStreams;
		}
	}, [formInputs]);

	const updateStreams = (name, value, id) => {
		const updatedStreams = formInputs.streams.map((stream, index) =>
			index === findStreamIndexToModify(id)
				? {
						...stream,
						[name]: name === 'defaut' ? (stream.defaut === 'O' ? 'N' : 'O') : value,
						duration:
							name === 'type'
								? value === TokenType.M6
									? 1400
									: value === TokenType.IndesRadios
										? 5
										: stream.duration
								: name === 'duration'
									? value
									: stream.duration,
						url_part_2:
							name === 'type'
								? value !== TokenType.M6 || value !== TokenType.IndesRadios
									? ''
									: stream.url_part_2
								: name === 'url_part_2'
									? value
									: stream.url_part_2,
						secret:
							name === 'type'
								? value !== TokenType.M6 || value !== TokenType.IndesRadios
									? ''
									: stream.secret
								: name === 'secret'
									? value
									: stream.secret,
					}
				: stream
		);
		setFormInputs({
			...formInputs,
			streams: updatedStreams,
		});
	};

	const addStream = () => {
		setFormInputs((prevInputs) => ({
			...prevInputs,
			streams: [...prevInputs.streams, newStream],
		}));
		const id = formInputs.streams.length;
		setNewStream({ defaut: 'N', url: '', url_part_2: '', bitrate: 64000, id: id });

		setTimeout(() => {
			document.getElementById(`url_${id}`).focus();
		}, 100);
	};
	const deleteStream = (index) => {
		const streamArray = [...formInputs.streams];
		streamArray.splice(index, 1);
		setFormInputs({
			...formInputs,
			streams: streamArray,
		});
	};
	return (
		<>
			{formInputs.streams.map((stream, index) => (
				<div key={index} className="my-5 row">
					<div className="col-11">
						<div className="row">
							<div className="mb-3 col-6">
								<label className="form-label d-flex justify-content-start">
									<b>
										{stream.type === TokenType.M6
											? `Début d'url ${index + 1} - Partie d’url avant le token`
											: stream.type === TokenType.IndesRadios
												? `Début d'url ${index + 1} - URL avant nom du fichier de flux`
												: `Stream ${index + 1}`}
									</b>
									{index >= 9 ? null : '\u00A0'}&nbsp; URL - par défaut
									<input
										key={`check_${index}`}
										id={`check_${index}`}
										type="checkbox"
										name="defaut"
										className="ms-1"
										checked={stream.defaut === 'O'}
										onChange={(e) => updateStreams(e.target.name, e.target.value, stream.id)}
									/>
								</label>
								<input
									className="form-control"
									type="text"
									name="url"
									key={`url_${index}`}
									id={`url_${index}`}
									value={stream.url}
									onChange={(e) => updateStreams(e.target.name, e.target.value.trim(), stream.id)}
								/>
							</div>
							<div className="mb-3 col-3">
								<label className="form-label d-flex justify-content-start">Type Value</label>
								<select
									className="form-select"
									name="type"
									key={`type_${index}`}
									id={`type_${index}`}
									value={stream.type}
									onChange={(e) => updateStreams(e.target.name, e.target.value, stream.id)}
								>
									<option value=""></option>
									<option value="icecast">MP3 ou AAC</option>
									<option value="hls">hls</option>
									{isAdmin ? (
										<>
											<option value="rtltoken">rtltoken</option>
											<option value="infomaniaktoken">infomaniaktoken</option>
										</>
									) : null}

									{!isAdmin &&
									(groupId === Number(GroupId.M6) || groupId === Number(GroupId.IndesRadios)) ? (
										<>
											<option
												value={
													groupId === GroupId.M6
														? 'rtltoken'
														: groupId === GroupId.IndesRadios
															? 'infomaniaktoken'
															: ''
												}
											>
												flux sécurisé
											</option>
										</>
									) : null}
								</select>
							</div>
							<div className="mb-3 col-3">
								<label className="form-label d-flex justify-content-start">Bitrate </label>
								<input
									className="form-control"
									type="text"
									name="bitrate"
									key={`bitrate_${index}`}
									id={`bitrate_${index}`}
									value={stream.bitrate || ''}
									onChange={(e) => updateStreams(e.target.name, e.target.value, stream.id)}
								/>
							</div>
						</div>
						{stream.type && (stream.type === TokenType.M6 || stream.type === TokenType.IndesRadios) && (
							<div className="row d-flex justify-content-start mb-4">
								<div className="mb-3 col-6">
									<b>
										<label className="form-label d-flex justify-content-start">Fin d'URL</label>
									</b>
									<input
										className="form-control"
										type="text"
										name="url_part_2"
										key={`url_part_2_${index}`}
										id={`url_part_2_${index}`}
										value={stream.url_part_2}
										onChange={(e) => updateStreams(e.target.name, e.target.value.trim(), stream.id)}
										placeholder={
											stream.type === TokenType.M6
												? "Partie d'url après le token"
												: stream.type === TokenType.IndesRadios
													? 'Nom du fichier avec extension'
													: null
										}
										required={
											stream.type === TokenType.M6 || stream.type === TokenType.IndesRadios
												? true
												: false
										}
									/>
								</div>
								<div className="mb-3 col-4">
									<label className="form-label d-flex justify-content-start">Clé token</label>

									<input
										className="form-control"
										type="text"
										name="secret"
										key={`secret_${index}`}
										id={`secret_${index}`}
										value={stream.secret || ''}
										onChange={(e) => updateStreams(e.target.name, e.target.value.trim(), stream.id)}
										required={
											stream.type === TokenType.M6 || stream.type === TokenType.IndesRadios
												? true
												: false
										}
									/>
								</div>
								{isAdmin ? (
									<div className="mb-3 col-2">
										<label className="form-label d-flex justify-content-start">
											Validité token en minutes
										</label>

										<input
											className="form-control"
											type="text"
											name="duration"
											key={`duration${index}`}
											id={`duration_${index}`}
											value={stream.duration || ''}
											onChange={(e) =>
												updateStreams(e.target.name, e.target.value.trim(), stream.id)
											}
											required={
												stream.type === TokenType.M6 || stream.type === TokenType.IndesRadios
													? true
													: false
											}
										/>
									</div>
								) : null}
							</div>
						)}
					</div>
					<div className="d-flex align-items-center justify-content-end col-1">
						<div className="streamDeleteButton" onClick={() => deleteStream(index)}>
							<MdDeleteForever size={20} />
						</div>
					</div>
				</div>
			))}

			<div className="justify-content-center my-3">
				<div className="btn btn-outline-secondary" onClick={addStream}>
					Ajouter Stream
				</div>
			</div>
		</>
	);
}

export default StreamsInputs;
